/*
Theme Name: CEME
Theme URI: https://wwww.groupe-ceme.com/
Description: WordPress Desert Theme
Version: 1.0
Author: MyJungly
Author URI: http://myjungly.com/
Tags: empty, full-width, simple, white
*/

@import '../../bower_components/bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');
@import '../../bower_components/slick-carousel/slick/slick.scss';
@import '../../bower_components/slick-carousel/slick/slick-theme.scss';
@import './bower_components/fancybox/dist/jquery.fancybox.min.css';
@import '../../bower_components/bootstrap-select/sass/bootstrap-select.scss';
@import '../../bower_components/bootstrap-fileinput/scss/fileinput.scss';
@import './bower_components/css-hamburgers/_sass/hamburgers/hamburgers.scss';
@import './bower_components/jquery-confirm2/dist/jquery-confirm.min.css';


@font-face {
    font-family:"Cosmos Medium";
    src:url("./assets/fonts/cosmos-bold.woff2") format("woff2"),url("./assets/fonts/cosmos-bold.woff") format("woff"),url("./assets/fonts/cosmos-bold.otf") format("opentype");
    font-style:normal;font-weight:400;
}

//#2D3E50', '#4AB19E', '#e74c3c', '#f39c12', '#2c3e50', '#c0392b

$mainBlue : #211C5D;
$secondBlue: #282780;
$purple: #6762A6;
$purpleClear: #A8A6BE;
$purpleClear2: #A4A3ED;
$mainGreen : #8CC63F;
$mainYellow : #FEC10E;
$secondYellow : #F4AC06;
$bgGrey: #F8F8F9;


//@import 'mouse-animation';

/*@import '../../bower_components/Font-Awesome/scss/fontawesome.scss';
@import '../../bower_components/Font-Awesome/scss/solid.scss';
@import '../../bower_components/Font-Awesome/scss/brands.scss';
@import '../../bower_components/Font-Awesome/scss/regular.scss';
@import '../../bower_components/aos/src/sass/aos.scss';*/

//@import 'hamburger';
//@import 'cursor-animation';



@mixin font-size($sizeValue: 1.6) {
    font-size: ($sizeValue * 10) + px !important;
    font-size: $sizeValue + rem !important;
}

@mixin line-height($sizeValue: 1.6) {
    line-height: ($sizeValue * 10) + px !important;
    line-height: $sizeValue + rem !important;
}

@mixin letter-spacing($sizeValue: 1.6) {
    letter-spacing: ($sizeValue * 10) + px !important; 
    letter-spacing: $sizeValue + rem !important;
}

@mixin font-smoothing($value: antialiased) {
    @if $value == antialiased {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

@mixin optional-at-root($sel) {
    @at-root #{if(not &, $sel, selector-append(&, $sel))} {
        @content;
    }
}

@mixin placeholder {
    @include optional-at-root('::-webkit-input-placeholder') {
        @content;
    }

    @include optional-at-root(':-moz-placeholder') {
        @content;
    }

    @include optional-at-root('::-moz-placeholder') {
        @content;
    }

    @include optional-at-root(':-ms-input-placeholder') {
        @content;
    }
}


.font-lato{
    font-family: 'Lato', sans-serif;
}

.font-cosmos{
    font-family: "Cosmos Medium", serif;
}

html {
    font-size: 62.5%; /* Sets up the Base 10 stuff */
    scroll-padding-top: 90px;
}


.main-wrap{
    max-width: 100vw;
    overflow: hidden;
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
}

.flex-grow {
    flex: 1 0 auto;
}

body{
    scroll-padding-top: 90px;
    @extend .font-lato;
    @include font-smoothing();
    @include font-size(1.6);
    @include line-height(2.3);   
    font-weight: 300; 
    color: $mainBlue;
    background: #fff;
    position: relative;
}

.cli-modal-backdrop{
    background: $mainBlue !important;
}

#cookie-law-info-again{
    box-shadow: 2px 2px 5px 2px rgba(#161616, .2) !important;
}

#cookie-law-info-bar{
    color: $mainBlue;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 4px 24px, rgba(0, 0, 0, 0.05) 0px 2px 6px !important;
    padding-bottom: 60px !important;
    border-radius: 5px 5px 5px 5px;
    overflow: hidden;
    width: 420px !important;
    max-width: calc(100% - 30px) !important;
    text-align: left !important;
    @include font-size(1.3);
    @include line-height(2);
    .sentence-icon{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img{
            max-width: 100%;
        }
        .sentence{
            text-align: justify;
            flex: 1;
        }
        .img{
            margin-left: 20px;
            flex: 0 0 84px;
        }
    }
    .cli_messagebar_head{
        @include font-size(1.6);
        @include line-height(2);
        font-weight: 400;
        margin-bottom: 10px;
        span{
            @include font-size(2.4);
            @include line-height(3);
            font-weight: 700;
        }
    }
    .buttons-rgpd{
        border-top: 1px solid #EDEDED;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        align-items: center;
        justify-content: stretch;
        .cli_action_button, .cli_settings_button{
            cursor: pointer !important;
            @include font-size(1.4);
            font-weight: bold;
            flex: 1;
            margin: 0 !important;
            background: #fff !important;
            color: $mainBlue !important;
            border-left: 1px solid #EDEDED;
            text-align: center;
            padding: 12px 18px;
            &.cli-plugin-main-button{
                color: $mainGreen !important;
            }
            &:first-child{
                border-left: 0;
            }
            &:hover{
                text-decoration: underline;
            }
        }
    }
}

.main-header{
    position: relative;
    z-index: 100;
    height: 133px;
    &-main{
        background: transparent;
        padding-top: 15px;
        padding-bottom: 15px;
        top: 43px;
        left: 0;
        width: 100%;
        .main-logo{
            img{
                width: 69px;
                height: 60px;
            }
            .logo-white{
                display: none;
            }
            .logo-dark{
                display: block;
            }
        }
        .hamburger{
            .hamburger-inner{
                background: $mainBlue;
                &:before, &:after{
                    background: $mainBlue;
                }
            }
        }
        .main-nav{
            margin: 0 0 0 auto;
            @include font-size(0);
            & > ul{
                padding: 0;
                margin: 0;
                & > li{
                    display: inline-block;
                    @include font-size(1.5);
                    margin: 0 13px;
                    font-weight: bold;
                    padding: 15px 0;
                    position: relative;
                    & > a{
                        color: $mainBlue;
                        text-decoration: none;
                        transition: color .2s ease;
                        &:hover{
                            color: $mainGreen;
                        }
                        &:after{
                            color: $mainBlue;
                        }
                    }
                    //&.current_page_parent
                    &.current_page_item, &.current-menu-item, &.current-menu-item-ancestor, &.current-page-ancestor{
                        &:after{
                            content: '';
                            position: absolute;
                            display: block;
                            bottom: 0;
                            width: 100%;
                            background: $mainGreen;
                            height: 4px;
                        }
                    }
                    &.menu-item-has-children{
                        & > a{
                            &:after{
                                margin-left: 5px;
                                font-family: 'Font Awesome 6 Pro';
                                content: '';
                            }
                        }
                        & > .sub-menu{
                            display: none;
                            position: absolute;
                            top: 100%;
                            left: 50%;
                            transform: translateX(-50%);
                            background: #fff;
                            overflow: hidden;
                            border-radius: 5px;
                            padding: 0;
                            margin: 0;
                            padding: 10px 0;
                            box-shadow: 0 6px 6px rgba(#19144D, .2);
                            width: 250px;
                            li{
                                display: block;
                                a{
                                    display: block;
                                    padding: 5px 20px;
                                    @include font-size(1.4);
                                    color: $mainBlue;
                                    text-decoration: none;
                                    transition: background .2s ease, color .2s ease;
                                    &:hover{
                                        color: $mainGreen;
                                        background: $bgGrey;
                                    }
                                }
                                &.current_page_item{
                                    a{
                                        color: $mainGreen;
                                    }
                                }
                                & > ul{
                                    padding-left: 10px;
                                    & > li{
                                        font-weight: normal;
                                    }
                                }
                            }
                        }
                        &:hover{
                            .sub-menu{
                                display: block;
                            }
                        }
                    }
                    
                }
            }
        }
        .search-link{
            @include font-size(1.6);
            vertical-align: middle;
            width: 30px;
            text-align: center;
            a{
                display: inline-block;
                width: 30px;
                text-align: center;
                color: $mainBlue;
            }
        }
        &.headroom {
            will-change: transform;
            transition: transform 200ms linear;
            &--pinned {
                transform: translateY(0%);
            }
            &--unpinned {
                transform: translateY(-100%);
            }
            &--not-top{
                position: fixed !important;
                top: 0;
                background: #fff;
            }
        }
        .menu-container{
            &.active{
                display: block !important;
                position: absolute;
                left: 0;
                right: 0;
                top: calc(100% + 15px);
                background: #fff;
                .main-nav{
                    text-align: left;
                    & > ul > li{
                        display: block;
                        width: 100%;
                        padding: 0;
                        margin: 0;
                        & > a{
                            display: block;
                            width: calc(100% - 38px);
                            padding: 15px;
                            color: $mainBlue !important;
                            @include font-size(2);
                            position: relative;
                            &:after{
                                display: none;
                            }
                        }
                        &.current_page_item, &.current-menu-item, &.current-menu-item-ancestor, &.current-page-ancestor{
                            & > a{
                                color: $mainGreen !important;
                            }
                            &:after{
                                @include font-size(2);
                                margin-left: 5px;
                                font-family: 'Font Awesome 6 Pro';
                                content: '';
                                color: $mainBlue !important;
                                position: absolute;
                                right: 15px;
                                top: 10px;
                                height: auto;
                                width: auto;
                                background: transparent;
                            }
                        }
                        &.menu-item-has-children{
                            position: relative;
                            &:after{
                                @include font-size(2);
                                margin-left: 5px;
                                font-family: 'Font Awesome 6 Pro';
                                content: '';
                                color: $mainBlue !important;
                                position: absolute;
                                right: 15px;
                                top: 10px;
                            }
                            .sub-menu{
                                position: relative;
                                width: 100%;
                                border-radius: none;
                                box-shadow: none;
                                a{
                                    @include font-size(1.8);
                                }
                            }
                        }
                    }
                }
            }
        }

    }
    &-preheader{
        background: $secondBlue;
        padding-top: 7px;
        padding-bottom: 7px;
        position: relative;
        z-index: 2;
        .mini-menu{
            .linkedin{
                display: inline-block;
                color: #fff;
                transition: color .2s ease;
                &:hover{
                    color: $mainGreen;
                }
            }
        }
        ul#menu-mini-menu-top{
            display: inline-block;
            @include font-size(0);
            padding: 0;
            margin: 0;
            & > li{
                display: inline-block;
                margin-right: 15px;
                position: relative;
                & > a{
                    @include font-size(1.4);
                    color: #fff;
                    transition: color .2s ease;
                    text-decoration: none;
                    font-weight: 700;
                    &:hover{
                        color: #fff;
                    }
                }
                &.current_page_item, &.current-menu-item-ancestor, &.current-page-ancestor{
                    & > a{
                        color: $mainGreen;
                    }
                }
                & > ul.sub-menu{
                    display: none;
                    position: absolute;
                    top: 100%;
                    left: 50%;
                    transform: translateX(-50%);
                    background: $secondBlue;
                    overflow: hidden;
                    border-radius: 5px;
                    padding: 0;
                    margin: 0;
                    padding: 10px 0;
                    box-shadow: 0 6px 6px rgba(#19144D, .2);
                    width: 250px;
                    li{
                        display: block;
                        a{
                            display: block;
                            padding: 5px 20px;
                            @include font-size(1.4);
                            color: #fff;
                            text-decoration: none;
                            transition: background .2s ease, color .2s ease;
                            font-weight: 700;
                            &:hover{
                                color: $mainGreen;
                            }
                        }
                        &.current_page_item, &.current-menu-item-ancestor, &.current-page-ancestor{
                            & > a{
                                color: $mainGreen;
                            }
                        }
                    }
                }
                &:hover{
                    & > a{
                        color: $mainGreen;
                    }
                    & > ul.sub-menu{
                        display: block;
                    }
                }
            }
        }
    }
}

body.home{
    .main-header-main{
        background: #fff;
    }
}
body.menu-white{
    .main-header{
        height: auto;
    }
    .main-header-main:not(.headroom--not-top){
        background: transparent;
        .main-logo{
            .logo-white{
                display: block;
            }
            .logo-dark{
                display: none;
            }
        }
        .hamburger{
            .hamburger-inner{
                background: #fff;
                &:before, &:after{
                    background: #fff;
                }
            }
        }
        .main-nav{
            & > ul{
                & > li{
                    & > a{
                        color: #fff;
                        &:after{
                            color: #fff;
                        }
                    }
                }
            }
        }
        .search-link a{
            color: #fff;
        }
    }
}
body.menu-absolute{
    .main-header{
        height: 43px;
        &-main{
            position: absolute;
        }
    }
}

.slider-full{
    .slider-init{
        position: relative;
        .slick-dots{
            position: absolute;
            bottom: 71px;
            @include media-breakpoint-down(lg) {
                bottom: 20px;
            }
            li{
                height: auto;
                width: auto;
                margin: 0;
                margin-right: 40px;
                @include media-breakpoint-down(lg) {
                    margin-right: 15px;
                }
                &:last-child{
                    margin-right: 0;
                }
                &:before{
                    display: none;
                }
                button{
                    text-indent: -41522px;
                    width: 8px;
                    height: 8px;
                    display: block;
                    background: #fff;
                    border-radius: 50%;
                    border: none;
                }
                &.slick-active{
                    button{
                        background: $mainGreen;
                    }
                }
            }
        }
    }
    .slick-dots{
            position: absolute;
            bottom: 71px;
            @include media-breakpoint-down(lg) {
                bottom: 20px;
            }
            li{
                height: auto;
                width: auto;
                margin: 0;
                margin-right: 40px;
                @include media-breakpoint-down(lg) {
                    margin-right: 15px;
                }
                &:last-child{
                    margin-right: 0;
                }
                &:before{
                    display: none;
                }
                button{
                    text-indent: -41522px;
                    width: 8px;
                    height: 8px;
                    display: block;
                    background: #fff;
                    border-radius: 50%;
                    border: none;
                }
                &.slick-active{
                    button{
                        background: $mainGreen;
                    }
                }
            }
        }
    .item{
        display: flex;
        height: 731px;
        align-items: center;
        @include media-breakpoint-down(lg) {
            height: 500px;
        }
        .bg-img{
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            object-fit: cover;
            z-index: 1;
        }
        .container{
            position: relative;
            z-index: 3;
        }
        &:before{
            content: '';
            display: block;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 2;
            position: absolute;
            background: rgba( #19144D, .5);
        }
        .uptitle{
            @include font-size(2.4);
            color: $mainGreen;
            margin-bottom: 16px;
            font-weight: bold;
        }
        .title{
            font-weight: bold;
            @include font-size(5.4);
            @include line-height(5.4);
            color: #fff;
            margin-bottom: 32px;
            @include media-breakpoint-down(lg) {
                @include font-size(4);
                @include line-height(4);
            }
        }
        .description{
            @include font-size(1.8);
            @include line-height(3);
            color: #fff;
        }
        .button-slider{
            margin-top: 32px;
            padding: 19px 24px 19px 24px;
            color: #fff;
            background: $mainGreen;
            display: inline-block;
            @include font-size(1.8);
            font-weight: bold;
            text-decoration: none;
            transition: background .2s ease, transform .2s ease;
            i{
                margin-left: 5px;
                transition: margin-left .2s ease;
            }
            &:hover, &:active{
                background: darken( $mainGreen, 5%);
                //transform: scale(1.05);
                i{
                    margin-left: 15px;
                }
            }
        }
    }
}

.custom-button{
    margin-top: 32px;
    padding: 19px 24px 19px 24px;
    color: #fff;
    background: $mainGreen;
    display: inline-block;
    @include font-size(1.8);
    font-weight: bold;
    text-decoration: none;
    transition: background .2s ease, transform .2s ease;
    border: none;
    box-shadow: none;
    cursor: pointer;
    i{
        margin-left: 5px;
        transition: margin-left .2s ease;
    }
    &:hover, &:active{
        background: darken( $mainGreen, 5%) !important;
        color: #fff;
        text-decoration: none;
        border: none;
        box-shadow: none !important;
        //transform: scale(1.05);
        i{
            margin-left: 15px;
        }
    }
}

.custom-button-purple{
    @extend .custom-button;
    background: transparent;
    color: $purpleClear;
    border: 1px solid $purpleClear; 
    transition: border-color .2s ease, color .2s ease;
    border: none;
    box-shadow: none;
    &:hover, &:active{
        border: none;
        box-shadow: none;
        border-color: $purple;
        color: $purple;
        background: transparent;
    }
}

.btn.btn-primary{
    @extend .custom-button;
}

.outside-container-w-100, .alignfull{
    width: 100vw !important;
    margin-left: calc( -50vw + 1140px/2 - 15px);
    @include media-breakpoint-down(lg) {
        margin-left: calc( -50vw + 930px/2);
    }
    @include media-breakpoint-down(md) {
        margin-left: calc( -50vw + 690px/2);
    }
    @include media-breakpoint-down(sm) {
        margin-left: calc( -50vw + 510px/2);
    }
    @include media-breakpoint-down(xs) {
        margin-left: -15px;
    }
    /*.wp-block-group__inner-container{
        @extend .container;
    }*/
}
.ignore-alignfull{
    .outside-container-w-100, .alignfull{
        width: 100% !important;
        margin-left: 0 !important;
        @include media-breakpoint-down(lg) {
            margin-left: 0 !important;
        }
        @include media-breakpoint-down(md) {
            margin-left: 0 !important;
        }
        @include media-breakpoint-down(sm) {
            margin-left: 0 !important;
        }
        @include media-breakpoint-down(xs) {
            margin-left: 0 !important;
        }
    }
}

.bg-map{
    background: url(./assets/img/img-html/bg-world.jpg) 0 50% no-repeat;
    background-size: contain;
}

.cpn-square{
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
}

.has-large-font-size{
    @include font-size(3);
    @include line-height(3.5);
    b, strong{
        font-weight: 600;
    }
}

.gutenberg-content{
    @include line-height(3);
    h1{
        @extend .big-title;
        margin-bottom: 20px;
    }
    h2, h3, h4{
        @extend .font-cosmos;
        margin-bottom: 20px;
    }
    h2{
        @include font-size(3.6);
        @include line-height(4.6);
        strong{
            font-weight: 500;
            color: $mainGreen;
        }
    }
    h3{
        @include font-size(2.6);
        @include line-height(3.6);
    }
    h4{
        @include font-size(1.6);
        @include line-height(2.6);
    }
    strong{
        font-weight: 700;
    }
    p{
        margin-bottom: 20px;
    }
    ul{
        li{
            list-style: none;
            &:before{
                content: '•';
                color: $mainGreen;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
            }
        }
    }
    .wp-block-gallery{
        li{
            &:before{
                display: none;
            }
        }
    }
}

.wp-block-quote{
    @include font-size(3);
    @include line-height(3);
    font-style: italic;
    cite{
        @include font-size(1.8);
        font-style: normal;
        color: $mainGreen;
    }
}

.has-maingrey-background-color{
    background: $bgGrey;
}

.has-maingreen-background-color{
    background: $mainGreen;
}

.has-mainyellow-background-color{
    background: $mainYellow;
}

.has-secondyellow-background-color{
    background: $secondYellow;
}

.has-secondeblue-background-color{
    background: $secondBlue;
}

.has-mainblue-background-color{
    background: $mainBlue;
}

.has-purple-background-color{
    background: $purple;
}

.has-purple-clear-background-color{
    background: $purpleClear2;
}

.has-white-color{
    color: #fff !important;
}

.has-white-background-color{
    background: #fff;
}

.wp-block-separator{
    height: 1px;
    border: none !important;
}

.wp-block-buttons{
    .wp-block-button{
        &.is-style-default{
            .wp-block-button__link{
                margin-top: 32px;
                padding: 15px 24px 15px 24px;
                border-radius: 0;
                border: none;
                color: #fff;
                background: $mainGreen;
                display: inline-block;
                @include font-size(1.8);
                font-weight: bold;
                text-decoration: none;
                transition: background .2s ease, transform .2s ease;
                i{
                    margin-left: 5px;
                    transition: margin-left .2s ease;
                }
                &:after{
                    margin-left: 5px;
                    font-family: 'Font Awesome 6 Pro';
                    content: '';
                    transition: margin-left .2s ease;
                }
                &:hover{
                    background: darken( $mainGreen, 5%);
                    //transform: scale(1.05);
                    i, &:after{
                        margin-left: 15px;
                    }
                }
                &.has-secondeblue-background-color{
                    background: $secondBlue;
                    &:hover{
                        background: darken( $secondBlue, 5%);
                    }
                }
            }
        }
        &.is-style-download{
            .wp-block-button__link{
                background: none;
                border: none;
                color: $mainBlue;
                font-weight: bold;
                @include font-size(1.6);
                transition: color .2s ease, transform .2s ease;
                padding: 0;
                &:before{
                    font-family: 'Font Awesome 6 Pro';
                    content: '';
                    color: $mainYellow;
                    margin-right: 5px;
                }
                &:after{
                    margin-left: 5px;
                    font-family: 'Font Awesome 6 Pro';
                    content: '';
                    transition: margin-left .2s ease;
                    color: $mainGreen;
                }
                &:hover{
                    color: lighten( $mainBlue, 15%);
                    //transform: scale(1.05);
                    i, &:after{
                        margin-left: 15px;
                    }
                }
            }
        }
        &.is-style-link-outside{
            .wp-block-button__link{
                color: $mainBlue;
                background: transparent;
                padding: 0;
                font-weight: bold;
                @include font-size(1.6);
                text-decoration: none;
                transition: color .2s ease;
                &:before{
                    font-family: 'Font Awesome 6 Pro';
                    content: '';
                    color: $mainYellow;
                    margin-right: 10px;
                }
                &:after{
                    font-family: 'Font Awesome 6 Pro';
                    content: '';
                    color: $mainGreen;
                    margin-left: 10px;
                    transition: margin-left .2s ease;
                }
                &:hover{
                    color: lighten($mainBlue, 10%);
                    &:after{
                        margin-left: 15px;
                    }
                }
            }
        }
        &.is-style-link-arrow{
            .wp-block-button__link{
                color: $mainBlue;
                background: transparent;
                padding: 0;
                font-weight: bold;
                @include font-size(1.6);
                text-decoration: none;
                transition: color .2s ease;
                &:before{
                    content: '';
                    background-color: $mainGreen;
                    margin-right: 10px;
                    height: 5px;
                    width: 5px;
                    display: inline-block;
                    border-radius: 50%;
                    vertical-align: middle;
                }
                &:after{
                    font-family: 'Font Awesome 6 Pro';
                    content: '';
                    color: $mainGreen;
                    margin-left: 10px;
                    transition: margin-left .2s ease;
                }
                &:hover{
                    color: lighten($mainBlue, 10%);
                    &:after{
                        margin-left: 15px;
                    }
                }
            }
        }
        &.margin-top-0{
            .wp-block-button__link{
                margin-top: 0 !important;
            }
        }
    }
}

.btn-download{
    border: none;
    color: $mainBlue;
    font-weight: bold;
    @include font-size(1.6);
    transition: color .2s ease, transform .2s ease;
    text-decoration: none;
    &:after{
        margin-left: 5px;
        font-family: 'Font Awesome 6 Pro';
        content: '';
        transition: margin-left .2s ease;
        color: $mainGreen;
    }
    &:hover{
        text-decoration: none;
        color: lighten( $mainBlue, 15%);
        //transform: scale(1.05);
        i, &:after{
            margin-left: 15px;
        }
    }
}

.jobs-component{
    /*&.outside-container-w-100{
        .item{
            &:nth-child(2){
                margin-top: 120px;
            } 
            &:nth-child(3){
                margin-top: 60px;
            } 
            @include media-breakpoint-down(md) {
                &:nth-child(2){
                    margin-top: 0;
                } 
                &:nth-child(3){
                    margin-top: 0;
                } 
            }
        }
    }*/
}

.cpn-timeline{
    background: $bgGrey;
    .row{
        position: relative;
        @include media-breakpoint-down(sm) {
            padding-bottom: 30px;
        }
        &:before{
            content: '';
            position: absolute;
            left: 50%;
            display: block;
            margin-left: -2px;
            height: 100%;
            width: 4px;
            background: $mainBlue;
            z-index: 1;
            @include media-breakpoint-down(sm) {
                left: 0;
            }
        }
        &:after{
            content: '';
            position: absolute;
            width: 30px;
            height: 30px;
            display: block;
            left: 50%;
            margin-left: -15px;
            bottom: -15px;
            background: $mainGreen;
            border-radius: 50%;
            z-index: 2;
            @include media-breakpoint-down(sm) {
                left: 0;
                bottom: 15px;
            }
        }
        &:nth-child(odd){
            .item{
                .item-inside{
                    &:after{
                        right: -20px;
                        transform: rotate(45deg);
                        @include media-breakpoint-down(sm) {
                            left: -20px;
                            right: auto;
                            transform: rotate(-45deg);
                        }
                    }
                }
            }
        }
        &:nth-child(even){
            .item{
                .item-inside{
                    &:after{
                        left: -20px;
                        transform: rotate(-45deg);
                    }
                }
            }
        }
        &:nth-child(1n){
            &:after{
                background: $mainGreen;
            }
        }
        &:nth-child(2n){
            &:after{
                background: $mainBlue;
            }
        }
        &:nth-child(3n){
            &:after{
                background: $mainYellow;
            }
        }
        &:nth-child(4n){
            &:after{
                background: $purple;
            }
        }
    }
    .item{
        .item-inside{
            background: #fff;
            padding: 30px;
            box-shadow: 0 2px 6px rgba( #6762A6, .1);
            position: relative;
            word-break: break-word;
            &:after{
                display: block;
                position: absolute;
                bottom: -3px;
                content: '';
                border-top: solid 20px #fff;
                border-left: solid 20px transparent;
                border-right: solid 20px transparent;
            }
            .date{
                color: $mainGreen;
                @include font-size(2.4);
                @extend .font-lato;
                font-weight: bold;
                margin-bottom: 15px;
            }
            .description{
                @include font-size(1.6);
                @include line-height(1.9);
                strong, b{
                    @include font-size(1.8);
                    font-weight: bold;
                }
                p{
                    margin-bottom: 10px;
                    &:last-child{
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
}

.listing-type{
    background: $bgGrey;
}

.items-preview{
    .item{
        height: 426px;
        position: relative;
        display: flex;
        text-decoration: none;
        padding: 60px;
        @include media-breakpoint-down(lg) {
            padding: 30px;
        }
        @include media-breakpoint-down(sm) {
            height: 400px;
        }
        .bg-img{
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            object-fit: cover;
            z-index: 0;
        }
        &:before{
            z-index: 1;
            content: '';
            display: block;
            bottom: 0;
            left: 0;
            right: 0;
            height: 320px;
            position: absolute;
            opacity: .5;
            background: linear-gradient(0deg, #282780 0%, rgba(40,39,128,0) 100%);
        }
        &:after{
            position: absolute;
            content: '';
            display: block;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            z-index: 2;
            background: #2F2971;
            opacity: 0;
            transition: opacity .2s ease;
        }
        .icon{
            position: absolute;
            right: 55px;
            top: 85px;
            z-index: 3;
            @include font-size(5);
            color: #fff;
            @include media-breakpoint-down(lg) {
                right: 30px;
                top: 30px;
            }
        }
        .content-inside{
            position: relative;
            z-index: 3;       
            h3{
                color: $mainGreen;
                @include font-size(2.4);
                font-weight: bold;
                text-decoration: none !important;
            }
            h4{
                @extend .font-cosmos;
                @include font-size(3.4);
                @include line-height(4);
                text-transform: uppercase;
                color: #fff;
                text-decoration: none !important;
            }
            .button-slider{
                padding: 0 24px 0 24px;
                height: 0;
                overflow: hidden;
                color: #fff;
                display: inline-block;
                background: $mainGreen;
                @include font-size(1.8);
                line-height: 63px;
                font-weight: bold;
                text-decoration: none;
                margin-top: 0;
                margin-bottom: 0;
                transition: background .2s ease, transform .2s ease, height .2s ease, margin-top .2s ease;
                i{
                    margin-left: 5px;
                    transition: margin-left .2s ease;
                }
                &:hover{
                    background: darken( $mainGreen, 5%);
                    //transform: scale(1.05);
                    i{
                        margin-left: 15px;
                    }
                }
            }
        }
        &:hover{
            &:after{
                opacity: .9;
            }
            .content-inside{
                text-decoration: none;
                h3, h4{
                    text-decoration: none;
                }
                .button-slider{
                    margin-top: 24px;
                    height: 61px;
                }
            }
        }
    }
}

.breadcrumb, .breadcrumbs{
    background: transparent;
    @include font-size(1.2);
    font-weight: bold;
    color: $purple;
    margin-bottom: 90px;
    padding: 0;
    .sep{
        margin: 0 10px;
    }
    a{
        color: $purple;
        font-weight: 400;
    }
}

.ban-page-top{
    padding-top: 90px;
    padding-bottom: 111px;
    min-height: 464px;
    &.no-content{

    }
    .breadcrumb, .breadcrumbs{
        margin-bottom: 90px;
        color: #fff;
        a{
            color: #fff;
        }
    }
    .inside-block{
        padding: 60px;
        background: #fff;
        .icon{
            position: absolute;
            right: 30px;
            bottom: 30px;
            @include font-size(5);
            color: $mainBlue;
        }
        @include media-breakpoint-down(md) {
            padding: 30px;
        }
        &.hide-bg{
            background: transparent;
            color: #fff !important;
            padding: 0;
        }
    }
}

.ban-secteur-top{
    .content{
        margin-top: 80px;
        margin-bottom: 80px;
        position: relative;
        z-index: 2; 
        .inside-block{
            padding: 60px 60px 60px 0;
            background: #fff;
            .up-title{
                color: $mainGreen;
                @extend .font-lato;
                font-weight: bold;
                @include font-size(2.4);
            }
            .sub-title{
                @extend .font-lato;
                font-weight: bold;
                @include font-size(2.4);
            }
            .button-slider{
                margin-top: 32px;
                padding: 19px 24px 19px 24px;
                color: #fff;
                background: $mainGreen;
                display: inline-block;
                @include font-size(1.8);
                font-weight: bold;
                text-decoration: none;
                transition: background .2s ease, transform .2s ease;
                i{
                    margin-left: 5px;
                    transition: margin-left .2s ease;
                }
                &:hover{
                    background: darken( $mainGreen, 5%);
                    //transform: scale(1.05);
                    i{
                        margin-left: 15px;
                    }
                }
            }
            .icon{
                position: absolute;
                right: 75px;
                bottom: 60px;
                @include font-size(5);
                color: $mainYellow;
                @include media-breakpoint-down(md) {
                    bottom: auto;
                    top: 60px;
                    @include font-size(5);
                }
                @include media-breakpoint-down(sm) {
                    bottom: auto;
                    top: 30px;
                    right: 45px;
                    @include font-size(4);
                }
            }
            @include media-breakpoint-down(md) {
                padding: 60px;
            }
            @include media-breakpoint-down(md) {
                padding: 30px;
            }
        }
    }
    .img-bg{
        z-index: 1;
        position: absolute;
        right: -15px;
        bottom: 0;
        top: 0;
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
            display: block;
        }
        @include media-breakpoint-down(md) {
            padding: 0;
            left: calc(50% - 50vw);
            right: calc(50% - 50vw);
            max-width: 100vw;
            width: 100vw;
        }
    }
}

.number-colored{
    padding: 30px 30px 20px 30px;
    color: #fff;
    height: 100%;
    .number-inside{
        @include font-size(9);
        @include line-height(9);
        @extend .font-lato;
        font-weight: bold;
        margin-bottom: 0;
    }
    .description{
        @include font-size(2.4);
        @include line-height(2.9);
        font-weight: bold;
    }
    .icon{
        @include font-size(4);
    }
}


.secteurs-listins{
    &.items-preview{
        .item{
            height: calc(50% - 30px);
            min-height: 300px;
            padding: 0 30px 30px 30px;
            margin-bottom: 30px;
            .icon{
                right: 30px;
                top: 30px;
            }
            .content-inside{
                h3{
                    color: $mainYellow;
                }
            }
        }
        .row{
            &:nth-child(odd){
                .col-lg-4:first-child(){
                    .item{
                        //height: calc(100% - 30px);
                    }
                }
            }
            /*&:nth-child(even){
                .col-lg-4:last-child(){
                    .item{
                        height: calc(100% - 30px);
                    }
                }
            }*/
        }
    }
}

.wp-block-image{
    img{
        @extend .img-fluid;
    }
}

.wp-block-media-text{
    .wp-block-media-text__content{
        padding: 0 0 0 30px;
    }
    &.has-media-on-the-right{
        .wp-block-media-text__content{
            padding: 0 30px 0 0;
        }
    }
    img{
        @extend .img-fluid;
    }
    @include media-breakpoint-down(sm) {
        width: 100%;
        margin-left: 0;
        display: flex;
        flex-direction: column;
        .wp-block-media-text__media{
            order: 1;
            margin-bottom: 30px;
        }
        .wp-block-media-text__content{
            order: 2;
            padding: 0 !important;
        }
    }
}

/*.partners{
    .item{
        filter: grayscale(100);
        transition: filter .2s ease;
        &:hover{
            filter: grayscale(0);
        }
    }
}*/

.listing-news{
    .item{
        a{
            transition: margin-top .2s ease, margin-bottom .2s ease, background .2s ease;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            margin-top: 0;
            background: #fff;
            height: 100%;
            box-shadow: 0 2px 6px rgba(#6762A6, .1);
            .thumb{
                height: 208px;
                position: relative;
                background: #ccc;
                img{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .content{
                flex: 1;
                padding: 30px;
                .meta{
                    color: $mainGreen;
                    font-weight: bold;
                    @include font-size(1.2);
                    margin-bottom: 8px;
                }
                .title{
                    color: $mainBlue;
                    font-weight: 800;
                    @include font-size(1.8);
                    @include line-height(2.3);
                    transition: color .2s ease;
                    @extend .font-lato;
                }
                .description{
                    color: $mainBlue;
                    font-weight: 8-400;
                    @include font-size(1.6);
                    @include line-height(2.3);
                    transition: color .2s ease;
                }
            }
            &:hover{
                margin-top: -10px;
                margin-bottom: 10px;
                text-decoration: none;
                background: $secondBlue;
                .content{
                    .title, .description{
                        color: #fff;
                    }
                }
            }
        }
    }
}

.listing-offres-arround{
    background: $bgGrey;
    .listing-offres{
        .item{
            a{
                transition: margin-top .2s ease, margin-bottom .2s ease, background .2s ease;
                display: flex;
                flex-direction: column;
                text-decoration: none;
                margin-top: 0;
                background: #fff;
                height: 100%;
                box-shadow: 0 2px 6px rgba(#6762A6, .1);
                .thumb{
                    height: 208px;
                    position: relative;
                    background: #ccc;
                    img{
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                .content{
                    flex: 1;
                    padding: 30px;
                    position: relative;
                    .meta{
                        color: $purple;
                        font-weight: bold;
                        @include font-size(1.2);
                        margin-bottom: 8px;
                    }
                    .title{
                        color: $mainBlue;
                        font-weight: 800;
                        @include font-size(1.8);
                        @include line-height(2.3);
                        transition: color .2s ease;
                        margin-bottom: 0;
                    }
                    .description{
                        color: $mainBlue;
                        font-weight: 8-400;
                        @include font-size(1.8);
                        @include line-height(2.3);
                        transition: color .2s ease;
                    }
                }
                &:hover{
                    margin-top: -10px;
                    margin-bottom: 10px;
                    text-decoration: none;
                    background: $secondBlue;
                    .content{
                        .title, .description{
                            color: #fff;
                        }
                    }
                }
                .logo-implantation{
                    img{
                        max-width: 70px;
                        height: auto;
                        display: block;
                    }
                }
            }
        }
    }
}


.listing-implantations{
    .item{
        a{
            transition: margin-top .2s ease, margin-bottom .2s ease, background .2s ease;
            display: flex;
            flex-direction: column;
            text-decoration: none;
            margin-top: 0;
            background: #fff;
            height: 100%;
            box-shadow: 0 2px 6px rgba(#6762A6, .1);
            .content{
                flex: 1;
                padding: 30px;
                .logo-implantation{
                    img{
                        max-width: 70px;
                        height: auto;
                        display: block;
                    }
                }
                .title{
                    color: $mainBlue;
                    font-weight: 800;
                    @include font-size(3);
                    @include line-height(3);
                    transition: color .2s ease;
                }
                .description{
                    color: $mainBlue;
                    font-weight: 400;
                    @include font-size(1.8);
                    @include line-height(2.2);
                    transition: color .2s ease;
                }
                .description{
                    color: $mainBlue;
                    font-weight: 400;
                    @include font-size(1.6);
                    @include line-height(2.3);
                    transition: color .2s ease;
                }
            }
            &:hover{
                margin-top: -10px;
                margin-bottom: 10px;
                text-decoration: none;
            }
        }
    }
}

.gutenberg-content .big-title{
    @extend .font-cosmos;
    @include font-size(7);
    @include line-height(7);
    .count{
        @include font-size(5);
        @extend .font-lato;
        color: $mainGreen;
    }
    @include media-breakpoint-down(lg) {
        @include font-size(5);
        @include line-height(5);
    }
    @include media-breakpoint-down(md) {
        @include font-size(3.8);
        @include line-height(3.8);
    }
}


.part-title{
    @extend .font-cosmos;
    @include font-size(3.6);
    @include line-height(3.6);
}


.main-footer{
    .logo-certif-footer{
        margin-bottom: 50px;
        img{
            max-height: 75px;
            width: auto;
            margin-right: 10px;
            height: auto;
            max-width: 100%;
            &:last-child{
                margin-right: 0;
            }
        }        
    }
    &-main{
        background: $mainBlue;
        padding: 100px 0;
        .widget-contact{
            color: $purple;
            .logo-footer{
                margin-bottom: 50px;
                margin-left: 37px;
            }
            .address, .phone{
                padding-left: 37px;
                position: relative;
                p{
                    margin-bottom: 30px;
                }
                .icon{
                    position: absolute;
                    left: 0;
                    top: 5px;
                }
                a{
                    color: $purple;
                    text-decoration: none;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }

            .address{
                .icon{
                    color: $mainYellow;
                }
            }
            .phone{
                .icon{
                    color: $mainGreen;
                }
            }
        }
        .widget{
            .widget-title{
                @include font-size(1.6);
                color: #fff;
                font-weight: bold;
                margin-bottom: 20px;
            }
            .widget-content{
                @include font-size(1.4);
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        margin-bottom: 5px;
                        a{
                            color: $purple;
                            transition: color .2s ease;
                            &:hover{
                                text-decoration: none;
                                color: lighten( $purple, 25%);
                            }
                        }
                    }
                }
            }
        }

    }
    &-bottom{
        background: #19144D;
        padding: 30px 0;
        p{
            color: $purple;
            margin-bottom: 0;
        }
        .mini-footer{
            ul{
                @include font-size(0);
                padding: 0;
                margin: 0;
                li{
                    display: inline-block;
                    margin-left: 30px;
                    a{
                        @include font-size(1.4);
                        color: $purple;
                        transition: color .2s ease;
                        text-decoration: none;
                        font-weight: bold;
                        &:hover{
                            color: #fff;
                        }
                    }
                }
            }
        }
        .linkedin, .youtube{
            @include font-size(1.8);
            color: $purple;
            transition: color .2s ease;
            text-decoration: none;
            font-weight: bold;
            &:hover{
                color: #fff;
            }
        }
    }
}

.wp-pagenavi{
     text-align: center;
     @include font-size(1.6);
     span, a{
         width: 50px;
         height: 50px;
         display: inline-block;
         text-align: center;
         line-height: 50px;
     }
     a.page, a.first, a.last{
        text-decoration: none;
        background: transparent;
        color: $mainBlue;
        transition: color .2s ease, background .2s ease;
        font-weight: bold;
        &:hover{
            background: $mainGreen;
            color: #fff;
            text-decoration: none;
        }
     }
     a.first, a.last{
         width: auto;
         padding: 0 20px;
     }
     a.first{
         i{
             margin-right: 10px;;
         }
     }
     a.last{
        i{
            margin-left: 10px;;
        }
    }
     span.current{
        font-weight: bold;
        background: $mainBlue;
        color: #fff;
     }
}

.bar-filters{
    background: $bgGrey;
    .inside-bar-filters{
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 2px 6px rgba(#6762A6, .1);
        .icon{
            line-height: 60px;
            text-align: center;
            height: 60px;
            width: 60px;
            color: $purpleClear;
        }
        .filter{
            margin-right: 30px;
        }
        .search-right{
            color: $purpleClear;
            @include font-size(1.6);
            border: none;
            input{
                text-align: right;
                border: none;
                outline: none;
                color: $purpleClear;
                @include media-breakpoint-down(lg) {
                    text-align: center;
                }
            }
            button{
                color: $purpleClear;
                background: none;
                border: none;
            }
        }
    }
}

.single-references, .single-implantations, .single-offres-emploi{
    .main-wrap{
        background: $bgGrey;
    }
    .ref-custom-content{
        .inside-ref{
            background: #fff;
            border-radius: 5px;
            padding: 60px;
            box-shadow: 0 -2px 4px rgba( #000, .1);
            @include media-breakpoint-down(sm){
                padding: 30px;
            }
            .ref-title{
                font-weight: 900;
                @include font-size(3.6);
                @include line-height(3.6);
                margin-bottom: 32px;
            }
            .table{
                th, td{
                    padding: 19px 0;

                }
                th{
                    color: $purpleClear;
                    @include font-size(1.8);
                    @include line-height(2.2);
                    padding-right: 60px;
                }
                td{
                    color: $mainBlue;
                    @include font-size(1.8);
                    @include line-height(2.2);
                }
            }
            .slider-ref{
                .slick-dots{
                    position: absolute;
                    bottom: -37px;
                    li{
                        &:before{
                            display: none;
                        }
                        height: auto;
                        width: auto;
                        margin: 0;
                        margin-right: 20px;
                        &:last-child{
                            margin-right: 0;
                        }
                        &:before{
                            display: none;
                        }
                        button{
                            text-indent: -41522px;
                            width: 8px;
                            height: 8px;
                            display: block;
                            background: $purpleClear;
                            border-radius: 50%;
                            border: none;
                        }
                        &.slick-active{
                            button{
                                background: $mainGreen;
                            }
                        }
                    }
                }
            }
            .share-job{
                ul{
                    margin: 0;
                    padding: 0;
                    li{
                        display: inline-block;
                        margin-left: 5px;
                        &:before{
                            display: none;
                        }
                        a, button{
                            width: 40px;
                            height: 40px;
                            display: block;
                            border-radius: 50%;
                            color: #fff;
                            text-align: center;
                            line-height: 40px;
                            border: none;
                            outline: none;
                        }
                        &.facebook{
                            a, button{
                                background: #2D53A1;
                            }
                        }
                        &.twitter{
                            a, button{
                                background: #19B2F5;
                            }
                        }
                        &.linkedin{
                            a, button{
                                background: #0e76a8;
                            }
                        }
                        &.copyurl{
                            a, button{
                                background: $mainYellow;
                            }
                        }
                    }
                }
            }
        }
        
    }
}

.single-post{
    .ban-secteur-top{
        .date{
            color: $mainGreen;
            margin-bottom: 10px;
            @include font-size(1.2);
            @include line-height(2);
            i{
                margin-right: 5px;
            }
        }
        .big-title{
           @include font-size(3);
           @include line-height(3);
           font-weight: 900;
           @extend .font-lato;
           text-transform: none !important; 
        }
    }
}

.bootstrap-select{ 
    div.dropdown-menu{
        border: none;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 6px 6px 0 rgba(25,20,77,0.2);
        padding: 24px 20px;
        top: 20px !important;
        li{
            @include font-size(1.4);
            &:before{
                display: none;
            }
            &:hover{
                color: $mainGreen;
            }
            .dropdown-item{
                color: $mainBlue;
                padding: 0;
                background: none;
                outline: none;
                .bs-ok-default{
                    display: none;
                    top: 0 !important;
                }
                &.selected{
                    .bs-ok-default{
                        display: block;
                        &:after{
                            font-family: 'Font Awesome 6 Pro';
                            content: '';
                            border-style: none;
                            transform: rotate(0);
                            display: block;
                            height: 0;
                            width: 0;
                        }
                    }
                }
            }
        }   
    }
    &.show > .btn-light.dropdown-toggle{
        background: transparent;
        @include font-size(1.6);
        color: $mainBlue;
        font-weight: bold;
        border: none;
        outline: none;
        color: $mainGreen;
    }
    .dropdown-toggle{
        background: transparent;
        @include font-size(1.6);
        color: $mainBlue;
        font-weight: bold;
        border: none;
        outline: none;
        &:hover{
            color: $mainGreen;
        }
        &.bs-placeholder, &.bs-placeholder:hover, &.bs-placeholder:focus{
            background: transparent;
            @include font-size(1.6);
            color: $mainBlue;
            font-weight: bold;
            border: none;
            outline: none;
        }
        &.bs-placeholder:hover{
            color: $mainGreen;
        }
        &:after{
            font-family: 'Font Awesome 6 Pro';
            content: '';
            border: none;
            margin: auto;
            height: auto;
            margin-left: 10px;
        }
    }
    &:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn){
        width: auto;
        max-width: 220px;
    }
}

#map-implantations{
    svg{
        max-width: 100%;
        height: auto;
        .pointer{
            transition: fill .2s ease;
            cursor: pointer;
            &:hover, &.active{
                fill: $mainBlue;
            }
        }
    }
}

.map-implantations-single{
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
}


.popover-implantations{
    .arrow{
        display: none;
    }
    .popover-body{
        box-shadow: 0 6px 6px 0 rgba(25,20,77,0.2);
        padding: 10px 20px;
        border-radius: 5px;
        width: 200px;
        border: 0 !important;
        ul{
            padding: 0;
            margin: 0;
            li{
                list-style: none;
                @include line-height(1.8);
                a{
                    color: $mainBlue;
                    display: inline-block;
                    @include font-size(1.4);
                    padding: 6px 0;
                    font-weight: bold;
                    text-decoration: none;
                    transition: color .2s ease;
                    &:hover{
                        color: $mainGreen;
                    }
                }
            }
        }
    }
}

.share-left{
    .title{
        @include font-size(1.2);
        color: $purple;
        margin-bottom: 10px;
        @include media-breakpoint-down(md){
            display: block;
            margin: 0 auto 10px auto;
        }
    }
    ul{
        margin: 0;
        padding: 0;
        li{
            display: block;
            margin-bottom: 5px;
            &:before{
                display: none;
            }
            a{
                width: 40px;
                height: 40px;
                display: block;
                border-radius: 50%;
                color: #fff;
                text-align: center;
                line-height: 40px;
            }
            &.facebook{
                a{
                    background: #2D53A1;
                }
            }
            &.twitter{
                a{
                    background: #19B2F5;
                }
            }
            &.linkedin{
                a, button{
                    background: #0e76a8;
                }
            }
            @include media-breakpoint-down(md){
                display: inline-block;
                margin-bottom: 5px;
                margin-left: 5px;
            }
        }
        @include media-breakpoint-down(md){
            display: inline-block;
        }
    }
    @include media-breakpoint-down(md){
        text-align: center;
        margin-bottom: 40px;
    }
}

.slick-dotted.slick-slider{
    margin-bottom: 0;
}

.blog{
    .slider-full{
        background: $bgGrey;
        padding-bottom: 64px;
    }
}

body.panel-open{
    overflow: hidden;
    height: 100% !important;
}

.overlay-ref, .overlay-postule-single, .overlay-postule-spont{
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    .overlay{
        background: rgba( $mainBlue, .9);
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
    .loading{
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 1;
        color: $mainGreen;
        transform: translate(-50%, -50%);
    }
    .panel{
        width: 690px;
        max-width: 100%;
        height: 100vh;
        height: calc(var(--vh, 1vh) * 100);
        position: absolute;
        top: 0;
        right: 0;
        background: #fff;
        padding: 60px;
        overflow: auto;
        z-index: 2;
        transform: translateX(100%);
        transition: transform .4s ease-in-out;
        @include media-breakpoint-down(md){
            padding: 30px;
        }
        .close{
            position: fixed;
            right: 20px;
            top: 20px;
            cursor: pointer;
            z-index: 100;
        }
        .panel-inside{
            .ref-title{
                font-weight: 900;
                @include font-size(3.6);
                @include line-height(3.6);
                margin-bottom: 32px;
            }
            .table{
                th, td{
                    padding: 19px 0;

                }
                th{
                    color: $purpleClear;
                    @include font-size(1.8);
                    @include line-height(2.2);
                    padding-right: 60px;
                }
                td{
                    color: $mainBlue;
                    @include font-size(1.8);
                    @include line-height(2.2);
                }
            }
            .slider-ref{
                .slick-dots{
                    position: absolute;
                    bottom: -37px;
                    li{
                        &:before{
                            display: none;
                        }
                        height: auto;
                        width: auto;
                        margin: 0;
                        margin-right: 20px;
                        &:last-child{
                            margin-right: 0;
                        }
                        &:before{
                            display: none;
                        }
                        button{
                            text-indent: -41522px;
                            width: 8px;
                            height: 8px;
                            display: block;
                            background: $purpleClear;
                            border-radius: 50%;
                            border: none;
                        }
                        &.slick-active{
                            button{
                                background: $mainGreen;
                            }
                        }
                    }
                }
            }
        }
    }
    &.open{
        .panel{
            transform: translateX(0);
        }
        .loading{
            display: none;
        }
    }
}

.overlay-postule-single, .overlay-postule-single, .overlay-postule-spont{
    .panel{
        width: 960px;
        background: #F8F8F9;
    }
}

.decor-top{
    position: absolute;
    top: 0;
    right: 0;
    background: url(./assets/img/form-decor-top.svg) 0 0 no-repeat;
    height: 270px;
    width: 234px;
    z-index: 0;
    &.contact{
        background: url(./assets/img/form-decor-top-contact.svg) 0 0 no-repeat;
        height: 210px;
        width: 234px;
    }
}
.decor-bottom{
    position: absolute;
    bottom: 170px;
    left: -90px;
    background: url(./assets/img/form-decor-bottom.svg) 0 0 no-repeat;
    height: 210px;
    width: 210px;
    z-index: 0;
}

.form-container{
    label{
        @include font-size(1.2);
        color: $purpleClear;
        font-weight: bold;
        width: 100%;
    }
    input[type='text'], input[type='email'], input[type='tel'], textarea{
        background: #fff;
        padding: 0 24px;
        line-height: 60px;
        height: 60px;
        box-shadow: 0 2px 6px 0 rgba(103,98,166,0.1);
        color: $mainBlue;
        font-weight: 400;
        @include font-size(1.6);
        border: 1px solid transparent;
        outline: none;
        width: 100%;
        &:focus{
            border-color: $mainGreen;
        }
        @include placeholder {
            color: $purpleClear;
        }
    }
    textarea{
        height: auto;
        @include line-height(2.2);
        padding: 20px 24px;
        min-height: 60px;
    }
    
    span.wpcf7-not-valid-tip{
        @include font-size(1.2);
        color: $red;
        text-align: right;
        margin-top: 5px;
    }
    .ajax-loader{
        padding: 30px !important;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        display: none !important;
        visibility: visible !important;
        &.is-active{
            display: block !important;
            visibility: visible !important;
        }
    }
    .file-input{
        @extend .mt-2;
        .input-group{
            @include media-breakpoint-down(sm){
                flex-direction: column;
            }
        }
        .form-control{
            pointer-events: none;
            background: #fff;
            padding: 0 24px;
            line-height: 60px;
            height: 60px;
            box-shadow: 0 2px 6px 0 rgba(103,98,166,0.1);
            color: $mainBlue;
            font-weight: 400;
            @include font-size(1.6);
            border: 1px solid transparent;
            outline: none;
            width: 100%;
            &:focus{
                border-color: $mainGreen;
            }
            .file-caption-name{
                padding: 0;
                color: $mainBlue;
                pointer-events: none;
            }
        }
        .input-group-btn{
            .btn-secondary{
                padding: 0 20px;
                line-height: 60px;
                height: 60px;
                @include font-size(1.8);
                @include media-breakpoint-down(sm){
                    flex: 1;
                }
            }
            .btn-primary{
                @extend .custom-button;
                margin-top: 0;
                height: 60px;
                border: none;
                line-height: 60px;
                padding-top: 0;
                padding-bottom: 0;
                cursor: pointer;
                @include media-breakpoint-down(sm){
                    flex: 1;
                }
                i{
                    display: none;
                }
                
            }
        }
    }
    .custom-control{
        color: $purple;
        padding-left: 30px;
    }
    .custom-control-label{
        &:before{
            width: 15px;
            height: 15px;
            left: -30px;
            top: 0;
        }
        &:after{
            width: 15px;
            height: 15px;
            left: -30px;
            top: 0;
        }
    }
    .custom-control-input:checked ~ .custom-control-label::before{
        background: $mainGreen;
        border-color: $mainGreen;
    }
    span.wpcf7-list-item{
        margin-left: 0;
    }
    
}

.custom-select-arround{
    position: relative;
    select.custom-select{
        background: #fff;
        padding: 0 24px;
        line-height: 60px;
        height: 60px;
        box-shadow: 0 2px 6px 0 rgba(103,98,166,0.1);
        color: $mainBlue;
        font-weight: 400;
        @include font-size(1.6);
        border: 1px solid transparent;
        outline: none;
        width: 100%;
        border-radius: 0;
        appearance: none;
        &:focus{
            border-color: $mainGreen;
        }
    }
    i{
        position: absolute;
        right: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
}

.page-template-tpl-contact{
    .custom-contact-head{
        background: #fff;
        padding: 60px;
        margin-top: -232px;
        margin: -232px 15px 0 15px;
        max-width: calc(100% - 30px);
    }
    .ban-page-top{
        height: 464px;
        min-height: auto;
    }
}



div.wpcf7-response-output{
    border-width: 1px;
    border-radius: 3px;
    margin: 20px 0 0 0 !important;
    @include font-size(1.4);
    border: 1px solid $red !important;
}
div.wpcf7-mail-sent-ok {
    border: 1px solid #4AB19E !important;
}
div.wpcf7-mail-sent-ng,
div.wpcf7-aborted {
    border: 1px solid $red !important;
}
div.wpcf7-spam-blocked {
    border: 1px solid #f39c12 !important;
}
div.wpcf7-validation-errors,
div.wpcf7-acceptance-missing {
    border: 1px solid #f39c12 !important;
}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing{
    border: 1px solid #f39c12 !important;
}

.jconfirm.jconfirm-white, .jconfirm.jconfirm-light{
    .jconfirm-box{
        padding: 60px;
        border-radius: 0;
        box-shadow: -2px 0 4px 0 rgba(0,0,0,0.1);
        div.jconfirm-title-c{
            margin-bottom: 32px;
            .jconfirm-title{
                @extend .font-lato;
                @include font-size(3);
                font-weight: 900;
            }
        }
        div.jconfirm-content-pane{
            @include font-size(1.8);
            @include line-height(3);
            margin-bottom: 30px;
        }
        .jconfirm-buttons{
            float: left;
            .custom-button{
                margin-top: 0;
                @extend .custom-button;
                border-radius: 0;
            }
        }
    }
    .jconfirm-bg{
        background: $mainBlue;
    }
}

.search-overlay{
    display: none;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 1000;
    .container{
        position: relative;
        z-index: 2;
    }
    &:after{
        content: '';
        z-index: 1;
        display: block;
        position: absolute;
        background: rgba( $mainBlue, .9);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
    .form-group{
        position: relative;
        .form-control{
            height: 60px;
            position: relative;
            z-index: 1;
            color: #fff;
            background: transparent;
            box-shadow: none;
            border-radius: 0;
            line-height: 60px;
            border: none;
            padding: 0 46px 0 46px;
            border-bottom: 1px solid #fff;
            @include font-size(3);
            @include placeholder {
                color: #fff;
                opacity: .5;
            }
        }
        button{
            z-index: 2;
            position: absolute;
            border: none;
            background: none;
            height: 60px;
            width: 46px;
            line-height: 60px;
            text-align: center;
            padding: 0;
            display: block;
            color: #fff;
            @include font-size(2.5);
            &.search-button{
                top: 0;
                left: 0;
                text-align: left;
            }
            &.close-button{
                right: 0;
                top: 0;
                text-align: right;
            }
        }
    }
}

.search-page{
    .search-form-detail{
        .form-group{
            position: relative;
            .form-control{
                height: 60px;
                position: relative;
                z-index: 1;
                color: $mainBlue;
                background: #fff;
                box-shadow: none;
                border-radius: 0;
                line-height: 60px;
                border: 1px solid transparent;
                padding: 0 60px 0 60px;
                @include font-size(1.6);
                box-shadow: 0 2px 6px 0 rgba(103,98,166,0.1);
                transition: border-color .2s ease;
                @include placeholder {
                    color: $mainBlue;
                    opacity: .5;
                }
                &:focus{
                    border-color: $mainGreen;
                }
            }
            button{
                z-index: 2;
                position: absolute;
                border: none;
                background: none;
                height: 60px;
                width: 46px;
                line-height: 60px;
                text-align: center;
                padding: 0;
                display: block;
                color: $mainBlue;
                @include font-size(1.8);
                &.search-button{
                    top: 0;
                    left: 0;
                }
                &.close-button{
                    right: 0;
                    top: 0;
                }
            }
        }
    }
    .search-item{
        margin-bottom: 40px;
        h3{
            margin-bottom: 0;
            a{
                color: $mainBlue;
                font-weight: bold;
                @include font-size(1.8);
                @include line-height(3);
                margin-bottom: 0;
            }
        }
        .description{
            margin-top: 15px;
            color: $mainBlue;
            margin-bottom: 0;
        }
        .link{
            margin-top: 5px;
            color: #0091FF;
            margin-bottom: 0;
        }
    }
}

.address-contact, .phone-contact, .mail-contact{
    padding-left: 37px;
    position: relative;
    .icon{
        position: absolute;
        left: 0;
        top: 8px;
    }
}

.address-contact{
    .icon{
        color: $mainYellow;
    }
}
.phone-contact{
    color: $mainBlue;
    a{
        color: $mainBlue;
    }
    .icon{
        color: $mainGreen;
    }
}

.mail-contact{
    color: #0091FF;
    a{
        color: #0091FF;
    }
    .icon{
        color: #0091FF;
    }
}

.container.skills{
    padding: 0 30px;
    .row{
        margin: 0 -30px;
        & > div[class*="col-"] {
            padding: 0 30px;
        }
    }
}

.error404{
    .message-404{
        background: url(./assets/img/bg-404.svg) 100% 40px no-repeat;
        padding: 50px 0;
        padding-right: 180px;
        .icon{
            margin: -10px 0 0 -100px;
        }
        p{
            @include font-size(1.8);
            @include line-height(3);
        }
        .custom-button{
            margin-top: 22px;
        }
    }
}


.grecaptcha-badge { 
    visibility: hidden !important;
}

/* IE11 */
@media all and (-ms-high-contrast:none)
{
    *::-ms-backdrop,
    [data-aos^=fade][data-aos^=fade],[data-aos^=zoom][data-aos^=zoom]{
        opacity: 1;
    }

    .d-flex{
        width: 100%;
        max-width: 100%;
    }
}

.tooltip{
    @include font-size(1.2);
}

.video-hide{
   background-color: #ccc;
}

.slider-full-normal{
    .slider-init {
      position: relative;
      padding-bottom: 80px;
      .slick-dots {
        position: absolute;
        bottom: 30px;
        @include media-breakpoint-down(lg) {
          bottom: 20px;
        }
        li {
          height: auto;
          width: auto;
          margin: 0;
          margin-right: 40px;
          @include media-breakpoint-down(lg) {
            margin-right: 15px;
          }
          &:last-child {
            margin-right: 0;
          }
          &:before {
            display: none;
          }
          button {
            text-indent: -41522px;
            width: 8px;
            height: 8px;
            display: block;
            background: $mainBlue;
            border-radius: 50%;
            border: none;
          }
          &.slick-active {
            button {
              background: $mainGreen;
            }
          }
        }
      }
    }
    .slick-slide{
      img{
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .wp-container-core-columns-layout-2{
    .partners{
      .item{
        @extend .col-md-4;
      }
    }
  }